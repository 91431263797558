import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Zoom } from "swiper/modules";
const swiper = new Swiper(".swiper", {
    modules: [Navigation, Pagination, Autoplay, Zoom],
    // Optional parameters
    loop: true,
    zoom: true,
    autoHeight: true,
    autoplay: {
        disableOnInteraction: false,
        delay: 3000,
        pauseOnMouseEnter: true,
    },
    // If we need pagination
    pagination: {
        el: ".swiper-pagination",
    },
    // Navigation arrows
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },

    // Default parameters
    slidesPerView: 1,
    // Responsive breakpoints
    breakpoints: {
        // when window width is >= 480px
        580: {
            slidesPerView: 2,
        },
        // when window width is >= 640px
        920: {
            slidesPerView: 3,
        },
        1460: {
            slidesPerView: 4,
        },
    },
});
